import React from 'react';
import Moment from 'moment';
import './PrintSheet.css';

class PrintSheet extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);

        this.state = {
            name: query.get('name'),
            critical: query.get('critical')
        }; 
    }

    render() {
        let {name, critical} = this.state;
        let date = new Date();
        Moment.locale('au');

        return (
            <React.Fragment>
                <div className="book">
                    <div className="page">
                        <div className="content">
                            <div className="header">
                                <table><tbody>
                                    <tr>
                                        <td className="icon left">DBM</td>
                                        <td className="center">Digital Build Manager Lead Sheet</td>
                                        <td className="icon right">DBM</td>
                                    </tr>
                                </tbody></table>
                                {critical==="true" ? <h1>CRITICAL</h1> : null}
                            </div>
                            <div className="body">
                                <div className="section grey">
                                    <table className="style-2"><tbody>
                                        <tr>
                                            <td><strong>Client Name:</strong> {name}</td>
                                            <td><strong>Date:</strong> {Moment(date).format('DD/MM/YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Client Number:</strong></td>
                                            <td><strong>Ticket Number:</strong></td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                <div className="section">
                                    <h4>Lights On</h4>
                                    <table className="border style-1"><tbody>
                                        <tr>
                                            <th>Type</th>
                                            <th>Booked?</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Notes</th>
                                        </tr>
                                        <tr>
                                            <td>Welcome Call</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Zoom Booked</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Asked about new marketing</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Asked about current marketing</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Winback</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                <div className="section">
                                    <h4>Additional Info</h4>
                                    <input type="text" className="lines"></input>
                                    <input type="text" className="lines"></input>
                                    <input type="text" className="lines"></input>
                                </div>
                                <div className="section grey">
                                    <h4>Digital Maintenance</h4>
                                    <table className="border style-1"><tbody>
                                        <tr>
                                            <th></th>
                                            <th>Yes</th>
                                            <th>No</th>
                                            <th>QWI</th>
                                            <th>Notes</th>
                                        </tr>
                                        <tr>
                                            <td>Is work completed?</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Was client called</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Client sent new credentials?</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Credentials updated in Infusionsoft?</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                <div className="section">
                                    <h4>The Talent</h4>
                                    <table className="border style-3"><tbody>
                                        <tr>
                                            <th>Team</th>
                                            <th>Notes</th>
                                        </tr>
                                        <tr>
                                            <td>Technical</td>
                                            <td>
                                                <span className="checkbox">Hosting Setup <input type="checkbox" /></span>
                                                <span className="checkbox">Handover <input type="checkbox" /></span>
                                                <span className="checkbox">Maintenance <input type="checkbox" defaultChecked /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Design</td>
                                            <td>
                                                <span className="checkbox">New Layout <input type="checkbox" /></span>
                                                <span className="checkbox">Ch1 <input type="checkbox" /></span>
                                                <span className="checkbox">Ch2 <input type="checkbox" /></span>
                                                <span className="checkbox">Ch3 <input type="checkbox" /></span>
                                                <span className="checkbox">Ch4 <input type="checkbox" /></span>
                                                <span className="checkbox">Ch5 <input type="checkbox" /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Development</td>
                                            <td>
                                                <span className="checkbox">New Development <input type="checkbox" /></span>
                                                <span className="checkbox">Ch1 <input type="checkbox" /></span>
                                                <span className="checkbox">Ch2 <input type="checkbox" /></span>
                                                <span className="checkbox">Ch3 <input type="checkbox" /></span>
                                                <span className="checkbox">Testing <input type="checkbox" /></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Programming</td>
                                            <td><span className="checkbox">Maintenance <input type="checkbox" /></span></td>
                                        </tr>
                                        <tr>
                                            <td>Copywriting</td>
                                            <td><span className="checkbox">Call Booked <input type="checkbox" /></span></td>
                                        </tr>
                                        <tr>
                                            <td>Marketing</td>
                                            <td>
                                                <span className="checkbox">Set Up <input type="checkbox" /></span>
                                                <span className="checkbox">Info Required <input type="checkbox" /></span>
                                            </td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                <div className="section grey">
                                    <h4>Accounts</h4>
                                    <table className="border style-4"><tbody>
                                        <tr>
                                            <th>Enquiry</th>
                                            <th>Tick</th>
                                            <th>Notes</th>
                                        </tr>
                                        <tr>
                                            <td>Paid 40%</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Paid 10%</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Invoice Required?</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Do we have a DDR?</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Check Charges?</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody></table>
                                </div>
                            </div> 
                            <div className="section">
                                    <h4>Additional Notes</h4>
                                    <input type="text" className="lines"></input>
                                    <input type="text" className="lines"></input>
                                    <input type="text" className="lines"></input>
                                </div>
                            <div className="footer"><table><tbody>
                                <tr>
                                    <td className="icon left">DBM</td>
                                    <td className="icon right">DBM</td>
                                </tr>
                            </tbody></table></div>
                        </div>  
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PrintSheet;