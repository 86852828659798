import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import SearchForm from './SearchForm';
import ListOppors from './ListOppors';
import PrintSheet from './PrintSheet';

class App extends React.Component {
  render() {
     return (
      //   <SearchForm/>
      <Router>
         <Route path="/" exact component={SearchForm}/>
         <Route path="/opportunity" component={ListOppors}/>
         <Route path="/print" component={PrintSheet}/>
      </Router>
     );
  }
}

export default App;
