import React from 'react';
import './SearchForm.css';
import CONFIG from './config.json';

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            authorized: null,
            infusionsoft_details: CONFIG
        };

        this.getAccessToken().then((res)=>{
            this.setState({
                authorized: JSON.parse(res)
            })
        })
    
        // window.onbeforeunload = function() {
        //     localStorage.clear();
        // }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async getAccessToken(){
        if(localStorage.getItem('access_token')){
            return Promise.resolve(localStorage.getItem('access_token'))
        } else if(window.location.search){
            var urlParams = new URLSearchParams(window.location.search);
            
            var data = this.state.infusionsoft_details;
            data['code'] = urlParams.get('code');
            
            const searchParams = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            }).join('&');

            const res = await fetch('https://api.infusionsoft.com/token', {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                method: 'POST',
                body: searchParams
            });
            const res_1 = await res.json();
            console.log(res_1);
            if (!res_1.error) {
                localStorage.setItem('access_token', JSON.stringify(res_1));
                return localStorage.getItem('access_token');
            }
            return Promise.resolve(null);
        } else{
          return Promise.resolve(null);
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        this.props.history.push(`/opportunity?firstname=${this.state.firstname}&lastname=${this.state.lastname}`)
    }

    render() {
        const { authorized, infusionsoft_details } = this.state
        return (
        <React.Fragment>
            <div className="search-form">
                {authorized ? 
                    <form onSubmit={this.handleSubmit}>
                        <fieldset>
                            <h3 className="heading heading--stroke heading--shadow">Client Search</h3>
                            <input name="firstname" type="text" value={this.state.name} onChange={this.handleChange} placeholder="First Name:" required/>
                            <input name="lastname" type="text" value={this.state.name} onChange={this.handleChange} placeholder="Last Name:" required/>
                        </fieldset>
                        <input type="submit" value="Search" />
                    </form> : 
                    <div>
                        <h3 className="heading heading--stroke heading--shadow">Login</h3>
                        <a href={`https://signin.infusionsoft.com/app/oauth/authorize?client_id=${infusionsoft_details['client_id']}&response_type=code&scope=full&redirect_uri=${infusionsoft_details['redirect_uri']}`}>
                            Click here to Login to Infusionsoft
                        </a>
                    </div>
                }
            </div>
        </React.Fragment>
        );
    }
}

export default SearchForm;