import React from 'react';
import Moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './ListOppors.css';
import CONFIG from './config.json';

class ListOppors extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(this.props.location.search);

        this.state = {
            firstname: query.get('firstname'),
            lastname: query.get('lastname'),
            opportunities: [],
            authorized: JSON.parse(localStorage.getItem('access_token')),
            baseURL: CONFIG.redirect_uri,
            orgStage: 251,
            newStage: 294
        }; 

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.toggleEmail = this.toggleEmail.bind(this);

        var clientId = CONFIG.clientid;
        var CLIENT_SECRET = CONFIG.clientsecret;
        
        var API_KEY = CONFIG.apikey;
        var scopes = 'https://mail.google.com/ '
        +'https://www.googleapis.com/auth/gmail.readonly '
        +'https://www.googleapis.com/auth/userinfo.profile '
        +'https://www.googleapis.com/auth/gmail.send '
        +'https://www.googleapis.com/auth/gmail.compose '
        +'https://www.googleapis.com/auth/gmail.modify';

        var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"];


        this.handleClientLoad(API_KEY, DISCOVERY_DOCS, clientId, scopes);
    }

    componentDidMount(){
        this.renderOpp(this.state.firstname, this.state.lastname, this.state.authorized);
    }

    async retrieveOpp(id){
        let oppDetails = {};

        let oppInfo = {
            optional_properties: "custom_fields",
            access_token: this.state.authorized.access_token
        };

        let searchOpp = id+'?'+Object.keys(oppInfo).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(oppInfo[key]);
        }).join('&');

        let opp_temp = await fetch(`https://api.infusionsoft.com/crm/rest/v1/opportunities/${searchOpp}`, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json; charset=UTF-8'
            },
            method: 'GET'
        });
        let opp = await opp_temp.json();
        if (!opp.error) {
            opp.custom_fields.forEach(custom_field => {
                if (custom_field.id === 257){
                    oppDetails.instruction = custom_field.content;
                } else if (custom_field.id === 259){
                    oppDetails.paidStatus = custom_field.content;
                }
            });
           
        }
        return oppDetails;
    }

    async renderOpp(firstname, lastname, authorized) {
        
        let opporsInfo = {
            limit: 99,
            offset: 0,
            stage_id: this.state.orgStage,
            search_term: firstname+' '+lastname,
            order: 'date_created',
            access_token: authorized.access_token
        };

        let searchOppors = Object.keys(opporsInfo).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(opporsInfo[key]);
        }).join('&');

        let oppors_temp = await fetch(`https://api.infusionsoft.com/crm/rest/v1/opportunities?${searchOppors}`, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json; charset=UTF-8'
            },
            method: 'GET'
        });
        let oppors = await oppors_temp.json();
        if (!oppors.error) {
            let oppors_array = [];
            if (oppors.count > 0){
                var self = this;
                oppors.opportunities.forEach(async function (item, index) {
                    let oppoId =  item.id;
                    let oppDetails = await self.retrieveOpp(oppoId);
                    oppors_array[index] = {
                        id: oppoId,
                        clientname: item.contact.first_name+" "+item.contact.last_name,
                        email: item.contact.email,
                        phone: item.contact.phone_number,
                        oppTitle: item.opportunity_title,
                        date: item.date_created,
                        instruction: oppDetails.instruction.replace(/&#013;/g, "<br />"),
                        paidStatus: oppDetails.paidStatus,
                    }
                    self.forceUpdate()
                });
                oppors_array['found'] = true;
            } else {
                oppors_array['found'] = false;
            }

            this.setState({
                opportunities: oppors_array
            });
        }
    }

    align(index){
        var emailSubject = this.state.opportunities[index].emailSubject ? this.state.opportunities[index].emailSubject : `Maintenance - ${this.state.opportunities[index].clientname} - Job`;
        var emailMessage = this.state.opportunities[index].emailMessage ? this.state.opportunities[index].emailMessage : this.state.opportunities[index].instruction;
        var critical = this.state.opportunities[index].status ? this.state.opportunities[index].status : false;

        var opportunityId = this.state.opportunities[index].id;
        var updateStage = {
            "stage": {
                "id": this.state.newStage
            }
        }

        // Change opportunity stage from orgStage ID to newStage ID
        fetch('https://api.infusionsoft.com/crm/rest/v1/opportunities/' + opportunityId + '?access_token=' + this.state.authorized.access_token, {
            method: 'PATCH',
            body: JSON.stringify(updateStage),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then(res => {
            toast.success('😎 Opportunity stage has been updated to: "' + res.stage.name + '" successfully!', {
                position: toast.POSITION.TOP_CENTER
            });

            // Send Email to the-talent ticket system - On Hold
            var headers = {
                'To': CONFIG.receiver,
                'Subject': emailSubject,
              }
            var email = '';
            for(var header in headers)
            email += header += ": "+headers[header]+"\r\n";
        
            email += "\r\n" + emailMessage;
        
            var sendRequest = window.gapi.client.gmail.users.messages.send({
                'userId': 'me',
                'resource': {
                    'raw': window.btoa(email).replace(/\+/g, '-').replace(/\//g, '_')
                }
            });
    
            sendRequest.execute(()=>{
                toast.success('😎 Email has been sent successfully!', {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    
            // Print Paper
            window.open(`${this.state.baseURL}/print?name=${this.state.firstname} ${this.state.lastname}&critical=${critical}`, '_blank');

        }).catch(err => {
            console.log(err);
        });
    }

    handleOnChange(event, index){
        let newOpportunities = this.state.opportunities
        newOpportunities[index].emailSubject = event.target.value
        this.setState({
            opportunities: newOpportunities
        });
    }

    handleCheck(event, index){
        let newOpportunities = this.state.opportunities
        newOpportunities[index].status = !newOpportunities[index].status
        this.setState({
            opportunities: newOpportunities
        });
    }

    toggleEmail(index){
        let newOpportunities = this.state.opportunities
        
        if( newOpportunities[index].align === true ){
            newOpportunities[index].align = false;
        } else {
            newOpportunities[index].align = true;
        }

        this.setState({
            opportunities: newOpportunities
        });
    }

    // Gmail Functions
    handleClientLoad = (API_KEY, DISCOVERY_DOCS, clientId, scopes) => {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/client.js";
    
        script.async = true
        script.onload = () => {
            window.gapi.load('client:auth2', () => {
                window.gapi.client.setApiKey(API_KEY);
                window.setTimeout(this.initClient(clientId, scopes), 1);
            });
        }
        document.body.appendChild(script)
    }
    
    initClient = (clientId, scopes) => {
        window.gapi.auth.authorize({
            client_id: clientId,
            scope: scopes,
            immediate: true
        }, () => {
              this.handleAuthClick(clientId, scopes)
            }
        );
    }

    handleAuthClick(clientId, scopes) {
        window.gapi.auth.authorize({
          client_id: clientId,
          scope: scopes,
          immediate: false
        }, ()=>{ window.gapi.client.load('gmail', 'v1') });
        return false;
    }
    
    render() {
        let { opportunities, align } = this.state;
        let i = 0;
        Moment.locale('au');

        return (
            <React.Fragment>
                {opportunities['found'] === true ?
                    <div className="opportunity-list">
                        <h3 className="heading heading--stroke heading--shadow">Search Results</h3>
                        <ul>
                            {
                                opportunities.map( (opportunity, index) => {
                                    i++;
                                    return (
                                        <li key={opportunity.id}>
                                            <legend><span className="number">{i}</span> {opportunity.oppTitle}</legend>
                                            <table><tbody>
                                            <tr><th>Client Name:</th><td>{opportunity.clientname}</td></tr>
                                            <tr><th>Email:</th><td>{opportunity.email}</td></tr>
                                            <tr><th>Phone:</th><td>{opportunity.phone}</td></tr>
                                            <tr><th>Opportunity Title:</th><td>{opportunity.oppTitle}</td></tr>
                                            <tr><th>Date Created:</th><td>{Moment(opportunity.date).format('DD/MM/YYYY')}</td></tr>
                                            <tr><th>Upgrades:</th><td>{opportunity.paidStatus}</td></tr>
                                            <tr><th>Special Instructions:</th><td><div dangerouslySetInnerHTML={{__html: opportunity.instruction}}/></td></tr>
                                            </tbody></table>
                                            <input type="button" onClick={() => {this.toggleEmail(index)}} value="Align this ticket" />
                                            {opportunity.align ? 
                                                <table className="send-email"><tbody>
                                                <tr><th>Email Subject:</th>
                                                    <td>
                                                        <textarea rows={1} defaultValue={`Maintenance - ${opportunity.clientname} - Job`} value={this.state.opportunities[index].emailSubject} onChange={(event) => this.handleOnChange(event, index)}></textarea>
                                                    </td>
                                                </tr>
                                                <tr><th>Email Body:</th>
                                                    <td>
                                                        <CKEditor
                                                            editor={ ClassicEditor }
                                                            data={opportunity.instruction}
                                                            onChange={ ( event, editor ) => {
                                                                let newOpportunities = this.state.opportunities
                                                                newOpportunities[index].emailMessage = editor.getData()
                                                                this.setState({
                                                                    opportunities: newOpportunities
                                                                })
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr><th>Critical?</th><td><input type="checkbox" value={this.state.opportunities[index].status} onChange={(event) => this.handleCheck(event, index)} /> Yes</td></tr>
                                                <tr><th></th>
                                                    <td>
                                                        <input type="button" id="align-button" onClick={() => {this.align(index)}} value="Align Now" />
                                                    </td>
                                                </tr>
                                                </tbody></table> : null
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul></div> : null
                }
                {opportunities['found'] === false ? 
                    <div className="opportunity-list">
                        <h3 className="heading heading--stroke heading--shadow">Search Results</h3>
                        <p>No opportunities are waiting to be aligned yet. Please check.</p> 
                    </div> : null
                }
                <ToastContainer autoClose={5000} transition={Zoom} position="top-center" hideProgressBar={false} newestOnTop />
            </React.Fragment>
        );
    }
}

export default ListOppors;
